
import { Vue, Component } from "vue-property-decorator";
import { isAndroid, isInStandaloneMode, isIos } from "@/plugins/utils";

@Component
export default class InstallIndex extends Vue {
    mounted() {
        if (isInStandaloneMode()) {
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                "Your are already using the app."
            );
            this.$router.push({ name: this.$constants.ROUTE_NAMES.HOME });
            return;
        }

        if (isAndroid()) {
            window.location.href = process.env.VUE_APP_ANDROID_APP_URL;
            return;
        }

        if (isIos()) {
            this.$router.push({
                name: this.$constants.ROUTE_NAMES.INSTALL_SAFARI,
            });
            return;
        }

        this.$router.push({
            name: this.$constants.ROUTE_NAMES.INSTALL_CHROME,
        });
        return;
    }
}
